import { Alert, Button } from "@mui/material";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import ApiStore from "../../Stores/ApiStore";

interface AskResetPasswordProps {
    apiStore?: ApiStore
}

const AskResetPassword = inject('apiStore')(observer(({ apiStore }: AskResetPasswordProps) => {

    if (!apiStore) {
        return null
    }

    const [email, setEmail] = useState<string>();
    const [emailSent, setEmailSent] = useState<boolean>(false);

    const askResetPassword = async () => {
        try {
            const body = {
                email: email
            }
            await apiStore.post('auth/ask-reset-password', body);
            setEmailSent(true);

            setTimeout(() => {
                setEmailSent(false);
            }, 5000);

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <form className="ask-reset-password">
            <h2>Réinitialisation de mot de passe</h2>
            <p>Saisissez votre adresse e-mail afin de recevoir un lien pour réinitialiser votre mot de passe.</p>

            <input
                type='email'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='login-input'
                placeholder='Email'
            />

            <Button
                variant='contained'
                onClick={askResetPassword}
                sx={{ marginTop: '30px' }}
            >
                Envoyer
            </Button>

            {emailSent &&
                <div className="alert-message">
                    <Alert variant="outlined" severity="success">
                        Si vous êtes un utilisateur, un email vous a été envoyé
                    </Alert>
                </div>
            }
        </form>
    )
}),
);

export default AskResetPassword;