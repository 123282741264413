import React, { useEffect, useState } from "react"
import { inject, observer } from "mobx-react";
import HeaderMenu from "../../Components/HeaderMenu";
import { Backdrop, Box, CircularProgress, Tab, Tabs } from "@mui/material";
import InvoiceTable from "../../Components/Invoices/InvoiceTable";
import { invoice } from "../../Utils/Types/invoice";
import UserStore from "../../Stores/UserStore";
import ApiStore from "../../Stores/ApiStore";
import CustomTabPanel from "../../Components/CustomTabPanel";

const Invoices = inject("userStore", "apiStore")(observer(({apiStore, userStore}: {userStore?: UserStore, apiStore?: ApiStore}) => 
{
    if(!apiStore || !userStore) return null 

    const [activeTab, setActiveTab] = useState<number>(0);
    const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false)
    const [quotations, setQuotations] = useState<Array<invoice>>()
    const [invoices, setInvoices] = useState<Array<invoice>>()

    const fetchAllDocuments = async () => 
    {
        setLoadingDocuments(true)
        try {
            const docs: Array<invoice> = await apiStore.get('billing/get-all') as Array<invoice>;
            setInvoices(docs.filter((quotation: invoice) => quotation.type === 'invoice'))
            setQuotations(docs.filter((quotation: invoice) => quotation.type !== 'invoice'))
        } catch (error) {
            console.error(error);
        }finally{
            setLoadingDocuments(false)
        }
    }

    const deleteDocument = async (id: string) => {
        try {
            await apiStore.get('billing/delete/' + id)
            fetchAllDocuments()
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() =>
    {
        fetchAllDocuments()
    }, [])

    return (
        <>
            <HeaderMenu headerText={"Factures et devis"} />
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs 
                    value={activeTab} 
                    onChange={(event: React.SyntheticEvent, newValue: number) => setActiveTab(newValue)} 
                >
                    <Tab label="Factures" />
                    <Tab label="Devis" />
                </Tabs>
                <CustomTabPanel value={activeTab} index={0}>
                    {invoices && 
                    <InvoiceTable
                        typeInvoice="invoice"
                        documents={invoices}
                        onDeleteDocument={deleteDocument}
                        onChangeStatus={fetchAllDocuments}
                    />}
                </CustomTabPanel>
                <CustomTabPanel value={activeTab} index={1}>
                    {quotations && 
                    <InvoiceTable
                        typeInvoice="quotation"
                        onDeleteDocument={deleteDocument}
                        documents={quotations}
                        onChangeStatus={fetchAllDocuments}
                    />}
                </CustomTabPanel>
            </Box>

            <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={loadingDocuments}>
                <CircularProgress color='inherit' />
            </Backdrop> 
        </>
    );
}))

export default Invoices;