import { Backdrop, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { clients } from "../../Utils/Types/client";

interface ClientsForm {
    client: clients
    visible: boolean
    submitText?: string
    onClose: () => void,
    onSubmit: (newClient: clients) => void
}

const ClientsForm = (({ client, visible, submitText, onClose, onSubmit }: ClientsForm) => {
    const [newClient, setnewClient] = useState<clients>(client);
    const [formSubmitted, setformSubmitted] = useState<boolean>(false);

    useEffect(() => {
        setnewClient(client)
    }, [client]);

    const handleInputChange = (text: string, key: string) => {
        setnewClient({
            ...newClient,
            [key]: text,
        });
    };

    const handleAddressChange = (text: string, key: string) => {
        setnewClient({
            ...newClient,
            address: {
                ...newClient.address,
                [key]: text,
            },
        });
    };

    return (
        <Backdrop
            sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={visible}
        >
            <form
                className='form-create-client'
                autoComplete='off'
                onSubmit={(event) => { event.preventDefault(); onSubmit(newClient) }}
            >
                <ClearOutlinedIcon
                    className='cancel-button-form-create-client'
                    onClick={onClose}
                />
                <h2>{submitText ? submitText : 'Ajouter'} un client</h2>
                <div className='form-client-input'>
                    <TextField
                        value={newClient.firstName}
                        label='Prénom'
                        variant='filled'
                        color='success'
                        inputProps={{
                            inputMode: 'text',
                            minLength: 2,
                            maxLength: 30,
                        }}
                        required
                        onChange={(event) =>
                            handleInputChange(
                                event.target.value,
                                'firstName',
                            )
                        }
                        helperText={
                            (formSubmitted && newClient.firstName.length > 50)
                                ? "Le prénom ne peut pas etre superieur à 50 caractères."
                                : ''
                        }
                        error={formSubmitted && newClient.firstName.length > 50}
                    />

                    <TextField
                        value={newClient.lastName}
                        label='Nom'
                        variant='filled'
                        required
                        inputProps={{
                            inputMode: 'text',
                            minLength: 2,
                            maxLength: 30,
                        }}
                        onChange={(event) =>
                            handleInputChange(
                                event.target.value,
                                'lastName',
                            )
                        }
                        helperText={
                            (formSubmitted && newClient.lastName.length > 50)
                                ? "L'nom ne peut pas etre superieur à 50 caractères."
                                : ''
                        }
                        error={formSubmitted && newClient.lastName.length > 50}
                    />
                </div>

                <TextField
                    value={newClient.entreprise}
                    label='Entreprise'
                    variant='filled'
                    onChange={(event) =>
                        handleInputChange(
                            event.target.value,
                            'entreprise',
                        )
                    }
                    helperText={
                        (formSubmitted && newClient.entreprise.length > 100)
                            ? "L'entreprise ne peut pas etre superieure à 100 caractères."
                            : ''
                    }
                    error={formSubmitted && newClient.entreprise.length > 100}
                />

                <div className='form-client-input'>
                    <TextField
                        value={newClient.email}
                        type='email'
                        autoComplete='false'
                        label='Email'
                        variant='filled'
                        required
                        inputProps={{
                            inputMode: 'email',
                        }}
                        onChange={(event) =>
                            handleInputChange(
                                event.target.value,
                                'email',
                            )
                        }
                        helperText={
                            (formSubmitted && newClient.email.length > 50)
                                ? "L'email ne peut pas etre superieur à 50 caractères."
                                : ''
                        }
                        error={formSubmitted && newClient.email.length > 50}
                    />
                    <TextField
                        value={newClient.phone}
                        type='tel'
                        label='Numéro de telephone'
                        variant='filled'
                        onChange={(event) =>
                            handleInputChange(
                                event.target.value,
                                'phone',
                            )
                        }
                    />
                </div>

                <TextField
                    value={newClient.address.addressLine}
                    label='Adresse'
                    variant='filled'
                    InputProps={{
                        inputProps: {
                            inputMode: 'text',
                            minLength: 4,
                            maxLength: 50,
                        },
                    }}
                    onChange={(event) =>
                        handleAddressChange(
                            event.target.value,
                            'addressLine',
                        )
                    }
                    helperText={
                        (formSubmitted && newClient.address.addressLine.length > 50)
                            ? "L'adresse ne peut pas etre superieure à 50 caractères et inferieure à 4 caractères."
                            : ''
                    }
                    error={formSubmitted && newClient.address.addressLine.length > 50}
                />

                <div className='form-client-input'>
                    <TextField
                        value={newClient.address.postalCode}
                        type='text'
                        label='Code Postale'
                        variant='filled'
                        onChange={(event) =>
                            handleAddressChange(
                                event.target.value,
                                'postalCode',
                            )
                        }
                    />
                    <TextField
                        value={newClient.address.city}
                        label='Ville'
                        variant='filled'
                        InputProps={{
                            inputProps: {
                                inputMode: 'text',
                                minLength: 3,
                                maxLength: 50,
                            },
                        }}
                        onChange={(event) =>
                            handleAddressChange(
                                event.target.value,
                                'city',
                            )
                        }
                        helperText={
                            (formSubmitted && newClient.address.addressLine.length > 50)
                                ? "La ville ne peut pas etre superieure à 50 caractères et inferieure à 3 caractères."
                                : ''
                        }
                        error={formSubmitted && newClient.address.addressLine.length > 50}
                    />

                    <TextField
                        value={newClient.address.country}
                        label='Pays'
                        variant='filled'
                        InputProps={{
                            inputProps: {
                                inputMode: 'text',
                                minLength: 3,
                                maxLength: 50,
                            },
                        }}
                        onChange={(event) =>
                            handleAddressChange(
                                event.target.value,
                                'country',
                            )
                        }
                        helperText={
                            (formSubmitted && newClient.address.country.length > 50)
                                ? "Le pays ne peut pas etre superieure à 50 caractères et inferieure à 3 caractères."
                                : ''
                        }
                        error={formSubmitted && newClient.address.country.length > 50}
                    />
                </div>
                <TextField
                    value={newClient.siret}
                    label='SIRET'
                    variant='filled'
                    InputProps={{
                        inputProps: {
                            inputMode: 'text',
                            minLength: 3,
                            maxLength: 20,
                        },
                    }}
                    onChange={(event) =>
                        handleInputChange(event.target.value, 'siret')
                    }
                />
                <Button
                    type='submit'
                    sx={{ alignSelf: 'flex-end', width: '120px' }}
                    onClick={() => setformSubmitted(true)}
                >
                    {submitText ? submitText : 'Créer'}
                </Button>
            </form>
        </Backdrop>
    )
}
);

export default ClientsForm;