import { inject, observer } from "mobx-react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import UserStore from "../../Stores/UserStore";
import { Autocomplete, Button, MenuItem, Paper, Select, SelectChangeEvent, TextField } from "@mui/material";
import ClientStore from "../../Stores/ClientStore";
import { useForm, useFieldArray } from "react-hook-form"
import { clients } from "../../Utils/Types/client";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import ApiStore from "../../Stores/ApiStore";
import { invoice } from "../../Utils/Types/invoice";

interface CreateInvoiceFormProps {
    userStore?: UserStore;
    clientStore?: ClientStore;
    apiStore?: ApiStore;
    document?: invoice;
    docType: string;
    onSubmit: (data: invoice) => void;
}

export interface clientAutocomplete extends clients {
    label?: string
}

const CreateInvoiceForm = inject("userStore", "clientStore", "apiStore")(observer((props: CreateInvoiceFormProps) => {

    const { userStore, clientStore, apiStore, docType, document, onSubmit } = props

    if (!userStore || !clientStore || !apiStore) {
        return null;
    }

    const [allClients, setAllClients] = useState<Array<clientAutocomplete>>([]);
    const [price, setPrice] = useState<number>(0.00);
    const [selectedCurrency, setSelectedCurrency] = useState<string>(document ? document.currency : 'EUR');
    const { register, handleSubmit, control, watch, getValues, setValue } = useForm<invoice>({
        defaultValues: document
            ?
            {
                ...document,
                date: new Date(document.date).toISOString().split('T')[0],
                expireDate: new Date(document.expireDate).toISOString().split('T')[0]
            }
            : {
                type: docType,
                title: "",
                project: "",
                date: new Date(),
                expireDate: new Date(),
                footer: "",
                totalPrice: 0,
                currency: selectedCurrency,
                header: "",
                changeRate: 1
            },
    })

    const formValues = watch();

    const { fields, append, remove } = useFieldArray({
        control,
        name: "content"
    });

    useEffect(() => {
        const clientsFormatted: Array<clientAutocomplete> = []

        clientStore.clients.map((client: clients) => {
            const newClient: clientAutocomplete = client
            newClient.label = newClient.firstName + " " + newClient.lastName
            clientsFormatted.push(newClient)
        })

        setAllClients(clientsFormatted)
    }, [clientStore.clients]);

    useEffect(() => {
        const content = getValues("content")
        const totalPrice = content.reduce((total, item) => total + parseFloat(item.price.toString()), 0);
        setPrice(totalPrice);

    }, [formValues]);

    const addItem = () => {
        append({ description: '', price: 0 });
    };

    const deleteItemForm = (index: number) => {
        remove(index)
    }

    const onAutocompleteChange = (event: SyntheticEvent<Element, Event>, values: clientAutocomplete | null) => {
        if (values)
            setValue('client', values)
    }

    const handleSaveInvoice = (data: invoice) => {
        data.totalPrice = price;
        data.currency = selectedCurrency
        onSubmit(data)
    }

    return (
        <form autoComplete="off" className="form-create-quotations">
            <div className="form-quotation-input">
                <TextField
                    label='Titre'
                    variant='filled'
                    {...register("title", { maxLength: 20 })}
                    style={{ flex: 2 }}
                />
                <TextField
                    type='date'
                    autoComplete='false'
                    label='Date'
                    variant='filled'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...register("date")}
                />
                <TextField
                    type='date'
                    autoComplete='false'
                    label='Date de validité'
                    variant='filled'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...register("expireDate")}
                />
            </div>

            <div className="form-quotation-input">
                <Autocomplete
                    style={{ width: '500px', flex: 1 }}
                    disablePortal
                    autoHighlight
                    sx={{ whiteSpace: "pre-wrap", p: 0, m: 1 }}
                    options={allClients}
                    value={formValues.client || ''}
                    getOptionLabel={(option) => `${option.firstName || ''} ${option.lastName || ''}`}
                    isOptionEqualToValue={(option, value) => (typeof value === 'string' ? value === '' : option._id === value?._id)}
                    onChange={onAutocompleteChange}
                    PaperComponent={(props) => (
                        <Paper
                            sx={{
                                background: '#303374',
                            }}
                            {...props}
                        />
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Clients"
                            variant='outlined'
                        />
                    )}
                />

                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCurrency}
                    sx={{ whiteSpace: "pre-wrap", p: 0, m: 1 }}
                    onChange={(event: SelectChangeEvent) => { setSelectedCurrency(event.target.value) }}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                backgroundColor: '#202248'
                            },
                        },
                    }}
                >
                    <MenuItem value={"CAD"} style={{ color: '#B1A9FF' }}>$CAD</MenuItem>
                    <MenuItem value={"USD"} style={{ color: '#B1A9FF' }}>$USA</MenuItem>
                    <MenuItem value={"EUR"} style={{ color: '#B1A9FF' }}>€EUR</MenuItem>
                </Select>

                <TextField
                    type="text"
                    label='Taux de change'
                    variant='outlined'
                    sx={{ whiteSpace: "pre-wrap", p: 0, m: 1 }}
                    style={{ flex: 2 }}
                    {...register("changeRate")}
                />
            </div>

            <div className="form-quotation-input">
                <TextField
                    type="text"
                    multiline
                    label='Entête'
                    variant='outlined'
                    sx={{ whiteSpace: "pre-wrap", p: 0, m: 1 }}
                    style={{ flex: 2 }}
                    {...register("header")}
                />
            </div>

            <div className="form-quotation-input">
                <Button
                    variant='contained'
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={addItem}
                >
                    Ajouter un service
                </Button>
            </div>

            {fields.map((item, index) => (
                <div className="form-quotation-input-item" key={item.id}>
                    <ClearOutlinedIcon
                        onClick={() => deleteItemForm(index)}
                        style={{
                            cursor: 'pointer'
                        }}
                    />
                    <TextField
                        type="text"
                        multiline
                        label='Description'
                        variant='outlined'
                        style={{ flex: 2 }}
                        {...register(`content.${index}.description`)}
                    />
                    <TextField
                        type="number"
                        placeholder="Prix"
                        variant='filled'
                        style={{ flex: 1 }}
                        {...register(`content.${index}.price`)}
                    />
                </div>
            ))}

            <h3>
                Montant total : {price.toLocaleString('fr-FR')} €
            </h3>

            {
                <div className="form-quotation-input">
                    <TextField
                        type="text"
                        multiline
                        label='Pied de page'
                        variant='outlined'
                        style={{ flex: 2 }}
                        sx={{ whiteSpace: "pre-wrap", p: 0, m: 1 }}
                        {...register("footer", { required: true })}
                    />
                </div>}

            <Button
                type='submit'
                sx={{ marginLeft: 'auto', marginRight: 'auto', width: '120px', display: 'block' }}
                onClick={handleSubmit(handleSaveInvoice)}
            >
                Enregistrer
            </Button>

        </form>
    )
}
))

export default CreateInvoiceForm;