import {
    observable,
    action,
    makeObservable
} from 'mobx';
import { RootStore } from '.';
import { clients } from '../Utils/Types/client';

export default class ClientStore {

    private rootStore: RootStore;

    @observable clients: Array<clients> = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        if(rootStore.userStore.isConnected && rootStore.userStore.isAdmin)
        {
            this.fetchAllClients()
        }
        makeObservable(this);
    }

    @action
    async setClients(clients: Array<clients>) {
        this.clients = clients;
    }

    @action
    async addClient(client: clients) {
        this.clients.push(client);
    }

    @action
    async removeClient(selectedClient: clients) {
        this.clients = this.clients.filter((client: clients) => client.email !== selectedClient.email);
    }

    @action
    async updateClient(selectedClient: clients) {
        let i = 0;
        while (this.clients[i]._id != selectedClient._id) {
            i++;
        }

        this.clients[i] = selectedClient;
    }

    async fetchAllClients() {
        try {
            let users: Array<clients> = await this.rootStore.apiStore.get('user/get-all') as Array<clients>;
            users = users.filter((user: clients) => user.role === 'CLIENT');
            this.setClients(users);
        } catch (error) {
            console.error(error);
        }
    }
}