import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
    Backdrop,
    TextField,
    Autocomplete,
    Paper,
    Button,
} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { project } from '../../Utils/Types/project';
import { ClientProjectConstructor, clientProject } from '../../Utils/Types/clientProject';
import { clients } from '../../Utils/Types/client';
import ClientStore from '../../Stores/ClientStore';
import { inject, observer } from 'mobx-react';

interface ProjectFormProps {
    project: project;
    visible: boolean;
    clientStore?: ClientStore,
    submitText?: string,
    onClose: () => void,
    onSubmit: (project: project, image?: File) => void
}

const ProjectForm = inject('clientStore')(observer(({ project, clientStore, visible, submitText, onClose, onSubmit }: ProjectFormProps) => {

    if (!clientStore) return null;

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [selectedImage, setSelectedImage] = useState<File>();
    const [newProject, setNewProject] = useState<project>(project);
    const hiddenFileInput = useRef<HTMLInputElement | null>(null);
    const [allClients, setAllClients] = useState<Array<clientProject>>([]);

    useEffect(() => {
        setNewProject(project);
    }, [project]);

    useEffect(() => {
        const clientsFormatted: Array<clientProject> = []

        clientStore.clients.map((client: clients) => {
            clientsFormatted.push(new ClientProjectConstructor(client))
        })

        setAllClients(clientsFormatted)
    }, [clientStore.clients]);

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files)
            setSelectedImage(event.target.files[0]);
    };

    const handleClick = () => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    const handleInputChange = (text: string, key: string) => {
        setNewProject({
            ...newProject,
            [key]: text,
        });
    };

    const handleDateChange = (text: string, key: string) => {
        setNewProject({
            ...newProject,
            date: {
                ...newProject.date,
                [key]: text,
            },
        });
    };

    //eslint-disable-next-line
    const onAutocompleteChange = (event: any, values: any) => {
        setNewProject({
            ...newProject,
            clients: values
        })
    }

    return (
        <Backdrop
            sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={visible}
        >
            <form
                className='form-create-project'
                autoComplete='off'
                style={{ width: '500px' }}
                onSubmit={(event) => { event.preventDefault(); onSubmit(newProject, selectedImage) }}
            >
                <ClearOutlinedIcon
                    className='cancel-button-form-create-project'
                    onClick={onClose}
                />
                <h1>{submitText ? submitText : 'Ajouter'} un projet</h1>

                <div className='form-project-input'>
                    <TextField
                        value={newProject.name}
                        label='Nom du projet'
                        variant='filled'
                        inputProps={{
                            inputMode: 'text',
                            minLength: 2,
                            maxLength: 50,
                        }}
                        required
                        onChange={(event) =>
                            handleInputChange(
                                event.target.value,
                                'name',
                            )
                        }
                        helperText={
                            (formSubmitted && newProject.name.length > 50)
                                ? "Le titre ne peut pas etre superieur à 50 caractères."
                                : ''
                        }
                        error={formSubmitted && newProject.name.length > 50}
                    />
                </div>

                <div className='form-project-input'>
                    <TextField
                        value={newProject.date.startDate}
                        type='date'
                        autoComplete='false'
                        label='Date'
                        variant='filled'
                        onChange={(event) =>
                            handleDateChange(
                                event.target.value,
                                'startDate',
                            )
                        }
                    />
                    <TextField
                        value={newProject.date.endDate}
                        type='date'
                        autoComplete='false'
                        label='Date'
                        variant='filled'
                        onChange={(event) =>
                            handleDateChange(
                                event.target.value,
                                'endDate',
                            )
                        }
                    />
                </div>

                <Autocomplete
                    multiple
                    disablePortal
                    options={allClients}
                    value={newProject.clients}
                    getOptionLabel={(option: clientProject) => `${option.firstName} ${option.lastName}`}
                    isOptionEqualToValue={(option: clientProject, value: clientProject) => option._id === value._id}
                    onChange={onAutocompleteChange}
                    PaperComponent={(props) => (
                        <Paper
                            sx={{
                                background: "#303374",
                            }}
                            {...props}
                        />
                    )}
                    renderInput={(params) => <TextField {...params} label="Clients" />}
                />

                <TextField
                    value={newProject.description}
                    label='Description'
                    variant='filled'
                    InputProps={{
                        inputProps: {
                            inputMode: 'text',
                            minLength: 0,
                            maxLength: 500,
                        },
                    }}
                    onChange={(event) =>
                        handleInputChange(
                            event.target.value,
                            'description',
                        )
                    }
                    helperText={
                        (formSubmitted && newProject.description.length > 500)
                            ? "La description ne peut pas etre superieure à 500 caractères."
                            : ''
                    }
                    error={formSubmitted && newProject.description.length > 50}
                />

                <label htmlFor="imageUpload">
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        id="imageUpload"
                        className="fileInput"
                        style={{ display: 'none' }}
                        ref={hiddenFileInput}
                    />
                    {!selectedImage ?
                        <Button variant="outlined" onClick={handleClick}
                            sx={{
                                background: 'white', backgroundColor: '#202248',
                                borderColor: '#4A4A95',
                                '&:hover': {
                                    backgroundColor: '#262A65',
                                    borderColor: '#5958B1'
                                },
                            }}>
                            Télécharger une photo
                        </Button> :
                        <Button variant="contained" onClick={handleClick}>
                            Photo téléchargée
                            <CheckCircleOutlineIcon
                                sx={{ marginLeft: "10px", fontSize: '20px' }}></CheckCircleOutlineIcon>
                        </Button>
                    }
                </label>

                <Button
                    type='submit'
                    sx={{ alignSelf: 'flex-end', width: '120px' }}
                    onClick={() => setFormSubmitted(true)}
                >
                    {submitText ? submitText : 'Créer'}
                </Button>
            </form>
        </Backdrop>
    );
})
);

export default ProjectForm;
