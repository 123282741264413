import { clients } from './client';

export interface clientProject {
	_id?: string;
	label: string;
	firstName: string;
	lastName: string;
}

export class ClientProjectConstructor implements clientProject {
	_id?: string;
	label: string;
	firstName: string;
	lastName: string;

	constructor(client: clients) {
		this._id = client._id;
		this.label = `${client.firstName} ${client.lastName}`;
		this.firstName = client.firstName;
		this.lastName = client.lastName;
	}
}
