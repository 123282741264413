import { inject, observer } from "mobx-react";
import HeaderMenu from "../../Components/HeaderMenu";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectConstructor, project } from "../../Utils/Types/project";
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { folderTree } from "../../Utils/Types/folderTree";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, Alert, IconButton, List, ListItem, Popover, TextField } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TreeStructure from "../../Components/Projects/TreeStructure";
import ProjectProgressBar from "../../Components/Projects/ProjectProgressBar";
import ProjectForm from "../../Components/Projects/ProjectForm";
import UserStore from "../../Stores/UserStore";
import ApiStore from "../../Stores/ApiStore";

interface ProjectProps {
    userStore?: UserStore
    apiStore?: ApiStore
}

const Project = inject("userStore", "apiStore")(observer(({ userStore, apiStore }: ProjectProps) => {
    if (!userStore || !apiStore) return null;

    const navigate = useNavigate();
    const [project, setProject] = useState<project>(new ProjectConstructor());
    const [documents, setDocuments] = useState<folderTree>();
    // eslint-disable-next-line
    const [anchorEl, setAnchorEl] = useState<any>();
    const [openDeleteAlert, setOpenDeleteAlert] = useState<boolean>(false);
    const [documentToDelete, setDocumentToDelete] = useState<folderTree>();
    const [folderNameDialog, setfolderNameDialog] = useState<boolean>(false);
    const [newFolderName, setNewFolderName] = useState<string>("");
    const [currentPath, setcurrentPath] = useState<string>("");
    const [sendingFileName, setsendingFileName] = useState<string>("");
    const [updateProjectModalVisible, setUpdateProjectModalVisible] = useState<boolean>(false);
    const [fileLoading, setFileLoading] = useState<boolean>(false)
    const { id } = useParams();

    useEffect(() => {
        fetchProject();
    }, []);

    const fetchProject = async () => {
        try {
            const project: project = await apiStore.get('project/get/' + id) as project;
            setProject(project);
            setFileLoading(true)
            fetchFiles()
        } catch (error) {
            console.error(error);
        }
    };

    const deleteProject = async () => {
        try {
            await apiStore.get('project/delete/' + id);
            navigate('/projects')
        } catch (error) {
            console.error(error);
        }
    }

    const fetchFiles = async () => {
        try {
            const documents: folderTree = await apiStore.get('project/retrive-documents/' + id) as folderTree;
            setDocuments(documents);
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setFileLoading(false)
        }
    };

    const deleteFileProject = async () => {
        try {
            const body = {
                id: documentToDelete?.id
            }

            await apiStore.post('documents/delete/', body);
            fetchFiles();
            setDocumentToDelete(undefined);
        } catch (error) {
            console.error(error);
        }
    }

    const uploadFile = async (document: File, path: string) => {
        try {
            const formData = new FormData();
            formData.append('file', document);
            formData.append('path', ',' + path);
            await apiStore.post('documents/upload/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            fetchFiles();
        }
        catch (e) {
            console.error(e);
        }

        setsendingFileName("")
    }

    const handleDrop = (event: React.DragEvent, path: string) => {
        event.preventDefault();
        const document: File = event.dataTransfer.files[0];

        if (document.size > 0) {
            setsendingFileName(document.name)
            uploadFile(document, path);
        }
    }

    const createFolder = async () => {
        try {
            let path = currentPath + newFolderName.replace(/,/g, '') + ','

            if (!path.startsWith(',')) path = ',' + path

            const body = {
                projectId: project._id,
                path
            };
            await apiStore.post('documents/create-folder', body);
            fetchFiles();
            setNewFolderName("");
            setfolderNameDialog(false);
        } catch (error) {
            console.error(error);
        }
    }

    const archiveProject = async (archived: boolean) => {
        const formattedProject: project = { ...project };

        formattedProject.archived = archived;

        const body = {
            data: JSON.stringify(formattedProject)
        }

        try {
            await apiStore.post('project/update/' + project._id, body);
            navigate("/projects")
        } catch (error) {
            console.error(error)
        }
    }

    const updateProject = async (newProjectUpdated: project, selectedImage: File | undefined) => {

        const formData = new FormData();

        if (selectedImage)
            formData.append('logo', selectedImage);

        const formattedNewProject: project = { ...newProjectUpdated };

        formData.append('data', JSON.stringify(formattedNewProject));

        try {
            await apiStore.post('project/update/' + project._id, formData, {
                'Content-Type': 'multipart/form-data',
            });
            setUpdateProjectModalVisible(false);
            fetchProject();
        } catch (error) {
            console.error(error)
        }
    };

    return (
        <>
            <HeaderMenu headerText={project.name} allowBack>
                {project.date !== undefined &&
                    <Box className='project-timeline-container'>
                        <p>{dayjs(project.date.startDate).format('DD/MM/YYYY')}</p>
                        <ProjectProgressBar project={project} />
                        <p>{dayjs(project.date.endDate).format('DD/MM/YYYY')}</p>
                        <div>
                            {userStore.isAdmin &&
                                <><IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}>

                                    <MoreHorizIcon />
                                </IconButton><Popover
                                    open={Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    onClose={() => setAnchorEl(null)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    PaperProps={{ style: { background: '#202248' } }}
                                >
                                        <List sx={{ cursor: 'pointer' }}>
                                            <ListItem onClick={() => { setAnchorEl(null); setUpdateProjectModalVisible(true) }}>Modifier</ListItem>
                                            {project.archived ?
                                                <ListItem onClick={() => archiveProject(false)}>Désarchiver</ListItem>
                                                :
                                                <ListItem onClick={() => archiveProject(true)}>Archiver</ListItem>
                                            }
                                            <ListItem onClick={() => setOpenDeleteAlert(true)}>Supprimer</ListItem>
                                        </List>
                                    </Popover></>
                            }

                            <Dialog
                                open={openDeleteAlert}
                                onClick={() => setOpenDeleteAlert(false)} aria-labelledby='alert-dialog-title'
                                aria-describedby='alert-dialog-description'
                            >
                                <DialogTitle id='alert-dialog-title'>
                                    {'Êtes-vous sûr de vouloir supprimer ce projet?'}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id='alert-dialog-description'>
                                        Si vous appuyez sur "Oui", toutes les données relatives à ce projet seront supprimées.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setOpenDeleteAlert(false)}>Non</Button>
                                    <Button onClick={deleteProject} autoFocus>
                                        Oui
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </Box>}
            </HeaderMenu>

            <h4 style={{ marginLeft: 35, color: '#B1A9FF', fontStyle: 'oblique' }}>Glissez vos fichiers dans les dossiers pour les déposer:</h4>

            <TreeStructure
                loading={fileLoading}
                rootName={project.name}
                userStore={userStore}
                documents={documents}
                handleDrop={function (event: React.DragEvent<HTMLDivElement>, path: string): void {
                    handleDrop(event, path)
                }}
                onDocumentDelete={function (tree: folderTree): void {
                    setDocumentToDelete(tree)
                }}
                onCreateFolder={function (path: string): void {
                    setcurrentPath(path)
                    setfolderNameDialog(true)
                }}
            />

            {sendingFileName &&
                <div className="alert-message">
                    <Alert variant="outlined" severity="info">
                        Envoi de {sendingFileName} en cours...
                    </Alert>
                </div>}

            {/* DIALOG TO DELETE FILE OR FOLDER */}
            <Dialog
                open={documentToDelete ? true : false}
                onClose={() => setDocumentToDelete(undefined)}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id="alert-dialog-title">
                    {'Êtes-vous sûr de vouloir supprimer ce fichier?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Si vous appuyez sur Oui, "{documentToDelete?.name}" sera supprimée.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDocumentToDelete(undefined)}>Non</Button>
                    <Button onClick={deleteFileProject} autoFocus>
                        Oui
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DIALOG TO CREATE FOLDER */}
            <Dialog
                open={folderNameDialog}
                onClose={() => setfolderNameDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Tapez le nom de le nouveau dossier"}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="folder"
                        label="Nom du dossier"
                        type="text"
                        fullWidth
                        value={newFolderName}
                        variant="standard"
                        onChange={(event) => setNewFolderName(event.target.value)}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setfolderNameDialog(false)}>ANNULER</Button>
                    <Button onClick={createFolder}>
                        CRÉER
                    </Button>
                </DialogActions>
            </Dialog>

            {/*Modify Project form*/}
            <ProjectForm
                project={project}
                visible={updateProjectModalVisible}
                onClose={() => setUpdateProjectModalVisible(false)}
                submitText="Modifier"
                onSubmit={(project: project, image: File | undefined) => updateProject(project, image)}
            />
        </>
    );
}))

export default Project;