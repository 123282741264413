import LinearProgress from '@mui/material/LinearProgress';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { project } from "../../Utils/Types/project";

const ProjectProgressBar = ({ project }: { project: project }) => {
    const [calculatedProgress, setcalculatedProgress] = useState<number>(0);

    useEffect(() => {
        calculateProgressBar()
    }, [project]);

    const calculateProgressBar = () => {
        const startDay = dayjs(project.date.startDate);
        const endDay = dayjs(project.date.endDate);

        const daysDifference = endDay.diff(startDay, 'day');
        const remainingDays = Math.max(0, endDay.diff(dayjs(), 'day'));

        setcalculatedProgress(100 - ((remainingDays === 0 ? daysDifference : remainingDays) / daysDifference) * 100);
    };

    return (
        <LinearProgress variant="determinate" value={calculatedProgress} sx={{ flexGrow: 1 }} />
    )
}

export default ProjectProgressBar