import React from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { API_PROJECT_LOGO } from '../../Utils/Variables';
import { project } from '../../Utils/Types/project';
import ClientStore from '../../Stores/ClientStore';
import { inject, observer } from 'mobx-react';

interface ProjectsCardProps {
    project: project,
    clientStore?: ClientStore,
}

const ProjectsCard = inject("apiStore", 'userStore', "clientStore")(observer(({ project, clientStore }: ProjectsCardProps) => {

    if (!clientStore) return null;

    return (
        <Link to={`/project/${project._id}`} style={{ textDecoration: "none" }}>
            <Card sx={{ width: 300, margin: '0 auto' }}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="200"
                        image={API_PROJECT_LOGO + project.photo}
                        alt="logo projet"
                        loading="lazy"
                    />
                    <CardContent>
                        <Typography
                            gutterBottom
                            variant="h6"
                            component="div"
                        >
                            {project.name}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Link>
    );
}))

export default ProjectsCard;
