import { Avatar, Button, TextField, Alert } from "@mui/material"
import HeaderMenu from "../../Components/HeaderMenu"
import { inject, observer } from "mobx-react"
import React, { useState } from "react"
import UserStore from "../../Stores/UserStore"
import ApiStore from "../../Stores/ApiStore"

interface ProfileProps {
    userStore?: UserStore,
    apiStore?: ApiStore
}

const Profile = inject("userStore", "apiStore")(observer(({ userStore, apiStore }: ProfileProps) => {

    if (!userStore || !apiStore) return null;

    if (!userStore.user) return null;

    const [user, setUser] = useState(userStore.user)
    const [newPassword, setNewPassword] = useState<string>('')
    const [currentPassword, setCurrentPassword] = useState<string>('')
    const [userModifiedAlertVisible, setUserModifiedAlertVisible] = useState<boolean>(false)

    const updateInformations = async (event: React.FormEvent) => {
        event.preventDefault()

        if ((user.phone.length > 0 && user.phone.length < 10) || (user.address.postalCode.length > 0 && user.address.postalCode.length < 5))
            return false

        try {
            const updatedUser: typeof user = await apiStore.post('user/update/' + user._id, user) as typeof user

            setUserModifiedAlertVisible(true)
            setTimeout(() => {
                setUserModifiedAlertVisible(false)
            }, 3000);
            userStore.setUser(updatedUser)
        } catch (error) {
            console.error(error)
        }

        return false
    }

    const updatePassword = async (event: React.FormEvent) => {
        event.preventDefault()

        const body = {
            password: currentPassword,
            newPassword
        }

        try {
            await apiStore.post('user/update-password/' + user._id, body)

            setUserModifiedAlertVisible(true)
            setTimeout(() => {
                setUserModifiedAlertVisible(false)
            }, 3000);
        }
        catch (error) {
            console.error(error)
        }

        return false
    }

    const handleUpdateUser = (text: string, key: string) => {
        setUser({
            ...user,
            [key]: text,
        });
    };

    const handleAddressUser = (text: string, key: string) => {
        setUser({
            ...user,
            address: {
                ...user.address,
                [key]: text,
            },
        });
    };

    return (<>
        <HeaderMenu headerText={'Mes paramètres'} />
        <div className="profile-settings-container">
            <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                <Avatar
                    src={user.photo}
                    sx={{ width: 70, height: 70 }}
                >
                    {user.firstName.charAt(0).toUpperCase()}{user.lastName.charAt(0).toUpperCase()}
                </Avatar>
                <h3>{user.firstName} {user.lastName}</h3>
            </div>
            <form className="profile-settings-form" onSubmit={(event) => updateInformations(event)}>
                <TextField
                    value={user.phone}
                    type='tel'
                    autoComplete='false'
                    label='Numéro de téléphone'
                    variant='filled'
                    onChange={(event) => handleUpdateUser(event.target.value, "phone")}
                    helperText={
                        (user.phone.length > 0 && user.phone.length < 10)
                        && "Le numéro doit faire 10 caractères"
                    }
                    error={user.phone.length > 0 && user.phone.length < 10}
                />

                <TextField
                    value={user.siret || ''}
                    type='text'
                    autoComplete='false'
                    label={`Numéro d'entreprise`}
                    variant='filled'
                    onChange={(event) => handleUpdateUser(event.target.value, 'siret')}
                />

                <div>
                    <TextField
                        value={user.address?.addressLine}
                        type='text'
                        autoComplete='false'
                        label='Adresse'
                        variant='filled'
                        onChange={(event) => handleAddressUser(event.target.value, "addressLine")}
                    />
                </div>

                <div className='form-client-input'>
                    <TextField
                        value={user.address?.postalCode}
                        type='text'
                        label='Code Postale'
                        helperText={
                            (user.address.postalCode.length > 0 && user.address.postalCode.length < 5)
                            && "Le code postal doit faire 5 caractères"
                        }
                        error={user.address.postalCode.length > 0 && user.address.postalCode.length < 5}
                        variant='filled'
                        onChange={(event) => handleAddressUser(event.target.value, "postalCode")}
                    />
                    <TextField
                        value={user.address?.city}
                        label='Ville'
                        variant='filled'
                        InputProps={{
                            inputProps: {
                                inputMode: 'text',
                                maxLength: 50,
                            },
                        }}
                        onChange={(event) => handleAddressUser(event.target.value, "city")}
                    />

                    <TextField
                        value={user.address?.country}
                        label='Pays'
                        variant='filled'
                        InputProps={{
                            inputProps: {
                                inputMode: 'text',
                                maxLength: 50,
                            },
                        }}
                        onChange={(event) => handleAddressUser(event.target.value, "country")}
                    />
                </div>
                <Button type="submit">Mettre à jour</Button>
            </form>

            <form className="profile-settings-form" onSubmit={(event) => updatePassword(event)}>
                <TextField
                    value={currentPassword}
                    type='password'
                    label='Ancien mot de passe'
                    variant='filled'
                    onChange={(event) => setCurrentPassword(event.target.value)}
                />
                <TextField
                    value={newPassword}
                    type='password'
                    label='Nouveau mot de passe'
                    variant='filled'
                    onChange={(event) => setNewPassword(event.target.value)}
                />
                <Button type="submit">Changer le mot de passe</Button>
            </form>
        </div>
        {userModifiedAlertVisible &&
            <div className="alert-message">
                <Alert variant="outlined" severity="success">
                    Votre compte à été mis à jour
                </Alert>
            </div>}
    </>
    )
}))

export default Profile