import React from 'react';
import ReactDOM from 'react-dom/client';
import './Styles/fonts.css';
import './Styles/index.css';
import './Styles/responsive.css';
import reportWebVitals from './reportWebVitals';
import Navigations from './Navigations/navigations';
import { Provider } from 'mobx-react';
import { rootStore } from './Stores';
import { ThemeProvider } from '@mui/material';
import theme from './Styles/theme';
import * as Sentry from "@sentry/react";
import { API_URL } from './Utils/Variables';

Sentry.init({
  dsn: "https://7f67126a6b169f49f30b1ea5b7c55d31@o4506587123417088.ingest.sentry.io/4506587161821184",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [API_URL],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider {...rootStore.getStores()}>
      <ThemeProvider theme={theme}>
        <Navigations />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
