import React, { useEffect, useState } from "react";
import HeaderMenu from "../../Components/HeaderMenu";
import CreateInvoiceForm from "../../Components/Invoices/CreateInvoiceForm";
import { useNavigate, useParams } from "react-router-dom";
import ApiStore from "../../Stores/ApiStore";
import { inject, observer } from "mobx-react";
import { invoice } from "../../Utils/Types/invoice";

interface UpdateInvoicesProps {
    apiStore?: ApiStore;
}

const UpdateInvoices = inject("apiStore")(observer(({ apiStore }: UpdateInvoicesProps) => {
    const { id } = useParams()
    if (!id || !apiStore) return null;

    const navigate = useNavigate();
    const [invoice, setinvoice] = useState<invoice>();

    useEffect(() => {
        const fetchDocument = async () => {
            try {
                const invoice = await apiStore.get('billing/get/' + id) as invoice;
                setinvoice(invoice)
            } catch (error) {
                console.error(error)
            }
        }
        fetchDocument();

    }, []);

    const updateInvoice = async (data: invoice) => {
        try {
            await apiStore.post('billing/update/' + data._id, data)
            navigate("/billing")
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <HeaderMenu headerText={invoice?.type === 'quotation' ? 'Mettre à jour le devis' : 'Mettre à jour la facture'} allowBack />

            {invoice && 
            <CreateInvoiceForm
                docType={invoice?.type || 'quotation'}
                onSubmit={updateInvoice}
                document={invoice}
            />}
        </>
    );
}))

export default UpdateInvoices;