import React, { useEffect, useState } from "react"
import { inject, observer } from "mobx-react";
import HeaderMenu from "../Components/HeaderMenu";
import UserStore from "../Stores/UserStore";
import ApiStore from "../Stores/ApiStore";
import { invoice } from "../Utils/Types/invoice";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend ,
    LinearScale,
    BarElement,
    CategoryScale,
    } from "chart.js";
import { useNavigate } from "react-router-dom";

ChartJS.register(
    CategoryScale, ArcElement, LinearScale, Tooltip, 
    BarElement,Legend);

const Home = inject("userStore", "apiStore")(observer(({ userStore, apiStore }: {userStore?: UserStore, apiStore?: ApiStore}) => {

    if(!userStore || !apiStore)
    {
        return null
    }

    const navigate = useNavigate();

    useEffect(() =>
    {
        if(userStore.user?.role === 'CLIENT')
        {
            navigate('/projects')
        }
    }, [])

    const [chartData, setChartData] = useState<any>(null);
    const [averageIncome, setaverageIncome] = useState<number>(0);

    useEffect(() => 
    {
        const fetchInvoices = async () =>
        {
            try {

                let invoices: Array<invoice> = await apiStore.get('billing/get-all') as Array<invoice>;
                invoices = invoices.filter((quotation: invoice) => 
                    (quotation.type === 'invoice' && quotation.status === 'payed')
                );
    
                const currentYear = new Date().getFullYear();
                const filteredInvoices = invoices.filter(
                  (invoice) => new Date(invoice.date).getFullYear() === currentYear
                );
            
                // group by months
                const groupedInvoices: { [month: number]: invoice[] }  = {};
                filteredInvoices.forEach((invoice) => {
                    const month = new Date(invoice.date).getMonth(); // Obtenez le mois (0-11)
                    if (!groupedInvoices[month]) {
                        groupedInvoices[month] = [];
                    }
                    groupedInvoices[month].push(invoice);
                });
            
                // Calculate amount per months
                const monthlyTotalPrices = Object.values(groupedInvoices).map((invoicesInMonth) =>
                    invoicesInMonth.reduce((total, invoice) => total + invoice.totalPrice, 0)
                );
                let sum = 0
                monthlyTotalPrices.forEach(function(num) { sum += num });
                setaverageIncome(parseInt((sum / monthlyTotalPrices.length).toFixed(0)))
                //update chart data
                setChartData({
                    labels: [
                        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
                    ],
                    datasets: [{
                        label: 'Chiffre d\'affaire',
                        data: monthlyTotalPrices,
                        backgroundColor: '#5B5BD6',
                        borderWidth: 1,
                    }]
                });
            } 
            catch (error) 
            {
                console.error(error)
            }
        }

        if(userStore.isAdmin)
        {
            fetchInvoices()
        }
    }, []);

    return (
        <>
            <HeaderMenu headerText={"Bienvenue " + userStore.user?.firstName} />
            {userStore.isAdmin && 
            <section style={{margin: 25}}>
                <h4>Chiffre d'affaire moyen par mois : {averageIncome.toLocaleString('fr-FR')} €</h4>
                <div style={{width: '40%'}}>
                    {chartData && <Bar data={chartData} />}
                </div>
            </section>}
        </>

    );
}))

export default Home;