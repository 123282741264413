import { inject, observer } from "mobx-react";
import HeaderMenu from "../../Components/HeaderMenu";
import React, { useEffect, useState } from "react";
import { ProjectConstructor, project } from "../../Utils/Types/project";
import { Box, Button, CircularProgress, Tab, Tabs } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClientStore from "../../Stores/ClientStore";
import ApiStore from "../../Stores/ApiStore";
import UserStore from "../../Stores/UserStore";
import ProjectForm from "../../Components/Projects/ProjectForm";
import ProjectsCard from "../../Components/Projects/ProjectsCard";

interface HomeProps {
    apiStore?: ApiStore,
    clientStore?: ClientStore,
    userStore?: UserStore
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}


const Home = inject("apiStore", 'userStore', "clientStore")(observer(({ apiStore, clientStore, userStore }: HomeProps) => {

    if (!apiStore || !clientStore || !userStore) {
        return null
    }

    const [projects, setProjects] = useState<Array<project>>([]);
    const [createProjectModal, setCreateProjectModal] = useState<boolean>(false);
    const [newProject, setNewProject] = useState<project>(new ProjectConstructor());
    const [projectsLoading, setProjectLoading] = useState<boolean>(false)
    const [value, setValue] = React.useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        fetchAllProjects();
    }, []);

    const fetchAllProjects = async () => {
        try {
            setProjectLoading(true)
            const project: Array<project> = await apiStore.get('project/get-projects') as Array<project>;
            setProjects(project);
            setProjectLoading(false)
        } catch (error) {
            console.error(error);
            setProjectLoading(false)
        }
    };

    const createNewProject = async (newProjectUpdated: project, selectedImage: File | undefined) => {
        const formData = new FormData();

        if (selectedImage)
            formData.append('logo', selectedImage);

        const formattedNewProject: project = { ...newProjectUpdated };
        delete formattedNewProject._id;

        formData.append('data', JSON.stringify(formattedNewProject));

        try {
            await apiStore.post('project/create', formData, {
                'Content-Type': 'multipart/form-data',
            });
            setCreateProjectModal(false);
            fetchAllProjects();
            setNewProject(new ProjectConstructor());
        } catch (error) {
            console.error(error)
        }
    };

    return (
        <>
            <HeaderMenu headerText={"Projets"} />

            {userStore.isAdmin &&
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleTabChange}>
                            <Tab label="En cours" />
                            <Tab label="Archives" />
                        </Tabs>
                    </Box>

                    <div style={{ textAlign: 'right', marginRight: '50px' }}>
                        <Button
                            variant='contained'
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={() => setCreateProjectModal(true)}
                        >
                            Ajouter un projet
                        </Button>
                    </div>
                </>
            }

            {/*ALL PROJECTS*/}
            <CustomTabPanel value={value} index={0}>
                <div className="projects-container">
                    {projectsLoading ?
                        <CircularProgress />
                        :
                        projects.filter(project => !project.archived).map((project: project) => (
                            <ProjectsCard project={project} key={"allprojects" + project._id} />
                        ))}
                </div>
            </CustomTabPanel>

            {/*ARCHIVED PROJECTS*/}
            <CustomTabPanel value={value} index={1}>
                <div className="projects-container">
                    {projectsLoading ?
                        <CircularProgress />
                        :
                        projects.filter(project => project.archived).map((project: project) => (
                            <ProjectsCard project={project} key={"archivedprojects" + project._id} />
                        ))}
                </div>
            </CustomTabPanel>

            {/*CLIENT PROJECTS*/}
            {
                !userStore.isAdmin &&
                <div className="projects-container">
                    {projectsLoading ?
                        <CircularProgress />
                        :
                        projects.map((project: project) => (
                            <ProjectsCard project={project} key={"clientprojects" + project._id} />
                        ))}
                </div>}

            {/*Create Project form*/}
            <ProjectForm
                project={newProject}
                visible={createProjectModal}
                onClose={() => setCreateProjectModal(false)}
                onSubmit={(project: project, image: File | undefined) => createNewProject(project, image)}
            />
        </>

    );
}))

export default Home;