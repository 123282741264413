import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../Pages/Login";
import Home from "../Pages/Home";
import { ProtectedRoute } from "./ProtectedRoute";
import Clients from "../Pages/Clients";
import Projects from "../Pages/Project/AllProjects";
import Project from "../Pages/Project/Project";
import Invoices from "../Pages/Invoices/Invoices";
import AskResetPassword from "../Pages/Profile/AskResetPassword";
import ResetPassword from "../Pages/Profile/ResetPassword";
import Profile from "../Pages/Profile/Profile";
import CreateInvoices from "../Pages/Invoices/CreateInvoices";
import UpdateInvoices from "../Pages/Invoices/UpdateInvoices";

const Navigations = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<ProtectedRoute element={<Home />} />} />
                <Route path="/clients" element={<ProtectedRoute element={<Clients />} />} />
                <Route path="/projects" element={<ProtectedRoute element={<Projects />} />} />
                <Route path="/project/:id" element={<ProtectedRoute element={<Project />} />} />
                <Route path="/billing" element={<ProtectedRoute element={<Invoices />} />} />
                <Route path="/ask-reset-password" element={<AskResetPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
                <Route path="/billing/create/:type" element={<ProtectedRoute element={<CreateInvoices />} />} />
                <Route path="/billing/update/:id" element={<ProtectedRoute element={<UpdateInvoices />} />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Navigations;