import { Alert, Button } from "@mui/material";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApiStore from "../../Stores/ApiStore";

const ResetPassword = inject('apiStore')(observer(({ apiStore }: { apiStore?: ApiStore }) => {
    if (!apiStore) {
        return null
    }

    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [passwordChanged, setPasswordChanged] = useState<boolean>(false);

    const navigate = useNavigate();
    const { token } = useParams()

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError("Les mots de passe ne correspondent pas.");
            return false;
        } else {
            setError("");
        }

        try {
            const body = {
                token: token,
                password: password
            }
            await apiStore.post('auth/reset-password', body);

            setTimeout(() => {
                setPasswordChanged(true);
                navigate("/login");
            }, 2000);
            setPasswordChanged(true);

        } catch (error) {
            console.error(error)
        }
    };

    return (
        <form className="ask-reset-password" onSubmit={handleSubmit}>
            <h1>Nouveau mot de passe</h1>
            <p>Veuillez saisir votre nouveau mot de passe</p>
            <input
                type='password'
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className='login-input'
                placeholder='Nouveau mot de passe'
            />
            <input
                type='password'
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className='login-input'
                placeholder='Confirmez le nouveau mot de passe'
            />
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <Button
                type="submit"
                variant='contained'
                sx={{ marginTop: '30px' }}
            >
                Réinitialiser mon mot de passe
            </Button>

            {passwordChanged &&
                <div className="alert-message">
                    <Alert variant="outlined" severity="success">
                        Votre mot de passe a été réinitialisé.
                        Vous allez être redirigé(e)...
                    </Alert>
                </div>
            }
        </form>
    );
}));

export default ResetPassword;
