import { inject, observer } from 'mobx-react';
import HeaderMenu from '../Components/HeaderMenu';
import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { ClientsConstructor, clients } from '../Utils/Types/client';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import ClientsForm from '../Components/Clients/ClientsForm';
import ApiStore from '../Stores/ApiStore';
import ClientStore from '../Stores/ClientStore';

interface ClientsProps {
    clientStore?: ClientStore,
    apiStore?: ApiStore,
}
const Clients = inject('apiStore', 'clientStore')(observer(({ apiStore, clientStore }: ClientsProps) => {

    if (!clientStore || !apiStore) return null;

    const [createClientModal, setcreateClientModal] = useState<boolean>(false);
    const [modifyClientModal, setmodifyClientModal] = useState<boolean>(false);
    const [selectedClient, setselectedClient] = useState<clients>(new ClientsConstructor());

    useEffect(() => {
        if (clientStore.clients.length === 0)
            clientStore.fetchAllClients()
    }, []);

    const deleteClient = async (id: string | undefined) => {
        try {
            const client: clients = await apiStore.get('user/delete/' + id) as clients;
            clientStore.removeClient(client);
        } catch (error) {
            console.error(error);
        }
    };

    const createNewClient = async (client: clients) => {
        const formattedNewClient: clients = { ...client };
        delete formattedNewClient._id;
        const randomstring = Math.random().toString(36).slice(-8);
        formattedNewClient.password = randomstring;

        try {
            const client: clients = await apiStore.post('user/create', formattedNewClient) as clients;
            clientStore.addClient(client);
            setcreateClientModal(false);
        } catch (error) {
            console.error(error)
        }
    };

    const updateClient = async (selectedClient: clients) => {
        const formattedNewClient: clients = { ...selectedClient };
        delete formattedNewClient._id;

        try {
            const client: clients = await apiStore.post('user/update/' + selectedClient._id, formattedNewClient) as clients;
            clientStore.updateClient(client);
            setmodifyClientModal(false);
        } catch (error) {
            console.error(error)
        }
    };

    return (
        <>
            <HeaderMenu headerText={'Clients'} />

            <div className='clients-table-container'>
                <Button
                    variant='contained'
                    startIcon={<AddCircleOutlineIcon />}
                    style={{ marginBottom: '20px' }}
                    onClick={() => setcreateClientModal(true)}
                >
                    Ajouter un client
                </Button>
                <TableContainer component={Paper}>
                    <Table aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className='table-head-title'
                                >
                                    Photo
                                </TableCell>
                                <TableCell
                                    className='table-head-title'
                                >
                                    Nom
                                </TableCell>
                                <TableCell
                                    className='table-head-title'
                                >
                                    Activité
                                </TableCell>
                                <TableCell
                                    className='table-head-title'
                                >
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clientStore.clients.map((client: clients, index: number) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        '&:last-child td, &:last-child th':
                                            { border: 0 },
                                    }}
                                >
                                    <TableCell
                                        align='center'
                                        component='th'
                                        scope='row'
                                    >
                                        <Avatar
                                            style={{
                                                margin: 'auto',
                                                display: 'block',
                                            }}
                                        ></Avatar>
                                    </TableCell>
                                    <TableCell align='left'>
                                        {client.firstName} {client.lastName}
                                    </TableCell>
                                    <TableCell align='center'>
                                        {client.lastActivity ? dayjs(client.lastActivity).format('DD/MM/YY HH:mm') : "..."}
                                    </TableCell>
                                    <TableCell align='center'>
                                        <IconButton
                                            aria-label='edit'
                                            onClick={() => {
                                                setmodifyClientModal(true);
                                                setselectedClient(client)
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            aria-label='delete'
                                            onClick={() =>
                                                deleteClient(client._id)
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            {/*Create Client form*/}
            <ClientsForm
                client={new ClientsConstructor()}
                visible={createClientModal}
                onClose={() => setcreateClientModal(false)}
                onSubmit={(newClient: clients) => createNewClient(newClient)}
            />

            {/*Modify Client form*/}
            <ClientsForm
                client={selectedClient}
                visible={modifyClientModal}
                onClose={() => setmodifyClientModal(false)}
                submitText='Modifier'
                onSubmit={(client: clients) => updateClient(client)}
            />
        </>
    );
}));

export default Clients;
