import React, { useMemo, useState } from "react";
import UserStore from "../../Stores/UserStore";
import { inject, observer } from "mobx-react";
import {
    Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
    IconButton, TableRow, Backdrop, CircularProgress, Select, MenuItem, SelectChangeEvent, Alert
} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from "react-router-dom";
import { invoice } from "../../Utils/Types/invoice";
import ApiStore from "../../Stores/ApiStore";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from "dayjs";
import { API_URL } from "../../Utils/Variables";

interface InvoiceTableProps {
    userStore?: UserStore
    apiStore?: ApiStore
    typeInvoice: string,
    documents: Array<invoice>
    onDeleteDocument: (id: string) => void
    onChangeStatus: () => void
}

const InvoiceTable = inject("userStore", "apiStore")(observer(({ userStore, apiStore, typeInvoice, documents, onDeleteDocument, onChangeStatus }: InvoiceTableProps) => {
    if (!userStore || !apiStore) {
        return null;
    }

    const navigate = useNavigate();
    const [isDownloading, setisDownloading] = useState<boolean>(false);
    const [downloadError, setdownloadError] = useState<boolean>(false);

    const totalByMonths = useMemo(() => {
        documents.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

        const res: any = {};

        documents.forEach((quotation) => {
            const monthYear = new Date(quotation.date).toLocaleString('fr-FR', { month: 'long', year: 'numeric' });

            if (!res[monthYear]) {
                res[monthYear] = 0;
            }
            res[monthYear] += quotation.totalPrice;
        });

        return res
    }, [documents])

    const allDocuments = useMemo(() => {
        documents.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

        const groupedQuotations: any = {};

        documents.forEach((quotation) => {
            const monthYear = new Date(quotation.date).toLocaleString('fr-FR', { month: 'long', year: 'numeric' });

            if (!groupedQuotations[monthYear]) {
                groupedQuotations[monthYear] = [];
            }
            groupedQuotations[monthYear].push(quotation);
        });

        return groupedQuotations
    }, [documents])

    const downloadDocument = async (invoice: invoice) => {
        setisDownloading(true);

        try {
            const response = await fetch(API_URL + `billing/preview/${invoice._id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Erreur lors du téléchargement du fichier');
            }

            const blob = await response.blob();

            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = (invoice.type === 'quotation' ? 'Devis - ' : 'Facture - ') + invoice.title + ".pdf";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
        catch (error) {
            console.error(error);

            setdownloadError(true)

            setTimeout(() => {
                setdownloadError(false)
            }, 2000);
        }
        finally {
            setisDownloading(false);
        }
    }

    const changeQuoteStatus = async (event: SelectChangeEvent, quotation: invoice) => {
        try {
            const body = {
                status: event.target.value
            };

            await apiStore.post('billing/update/' + quotation._id, body);
            onChangeStatus()
        }
        catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <div className='invoices-table-container'>
                <Button
                    variant='contained'
                    startIcon={<AddCircleOutlineIcon />}
                    style={{ marginBottom: '20px' }}
                    onClick={() => navigate("/billing/create/" + typeInvoice)}
                >
                    Créer {typeInvoice == 'quotation' ? 'un devis' : 'une facture'}
                </Button>

                <TableContainer component={Paper}>
                    <Table aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className='table-head-title'
                                >
                                    Titre
                                </TableCell>
                                <TableCell
                                    className='table-head-title'
                                >
                                    Date
                                </TableCell>
                                <TableCell
                                    className='table-head-title'
                                >
                                    Client
                                </TableCell>
                                <TableCell
                                    className='table-head-title'
                                >
                                    Status
                                </TableCell>
                                <TableCell
                                    className='table-head-title'
                                >
                                    Total
                                </TableCell>
                                <TableCell
                                    className='table-head-title'
                                >
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                        {Object.entries(allDocuments).map(([monthYear, quotationsInMonth]: any, index) => (
                            <React.Fragment key={index}>
                                <TableRow>
                                    <TableCell
                                        colSpan={6}
                                        align="left"
                                        sx={{ fontWeight: 'bold', fontSize: '1rem', backgroundColor: '#262A65' }}
                                    >
                                        {monthYear.toString().charAt(0).toUpperCase() + monthYear.slice(1)}
                                        {' total : ' + totalByMonths[monthYear].toLocaleString('fr-FR')} €
                                    </TableCell>
                                </TableRow>

                                {quotationsInMonth.map((quotation: any, index: any) => (
                                    <TableRow key={index}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                { border: 0 },
                                        }}>
                                        <TableCell align='center' component='th' scope='row'>
                                            {quotation.title}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {dayjs(quotation.date).format('DD/MM/YYYY')}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {quotation.client.entreprise || (`${quotation.client.firstName} ${quotation.client.lastName}`)}
                                        </TableCell>
                                        <TableCell align='center'>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <Select
                                                    value={quotation.status}
                                                    onChange={(e) => changeQuoteStatus(e, quotation)}
                                                    style={{ width: '100%' }}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                backgroundColor: '#202248'
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={'created'} style={{ color: '#B1A9FF' }}>Créée</MenuItem>
                                                    <MenuItem value={'sent'} style={{ color: '#B1A9FF' }}>Envoyée</MenuItem>
                                                    <MenuItem value={'payed'} style={{ color: '#B1A9FF' }}>Payée</MenuItem>
                                                    <MenuItem value={'late'} style={{ color: '#B1A9FF' }}>En retard</MenuItem>
                                                </Select>
                                                <div
                                                    style={{
                                                        width: 20,
                                                        aspectRatio: 1/1,
                                                        borderRadius: '50%',
                                                        backgroundColor:
                                                            quotation.status === 'created'
                                                                ? 'grey'
                                                                : quotation.status === 'sent'
                                                                    ? 'blue'
                                                                    : quotation.status === 'late'
                                                                        ? 'red'
                                                                        : quotation.status === 'payed'
                                                                            ? 'green'
                                                                            : 'grey',
                                                        marginLeft: 10,
                                                        alignItems: 'right'
                                                    }}
                                                ></div>
                                            </div>
                                        </TableCell>
                                        <TableCell align='center'>
                                            {quotation.totalPrice.toLocaleString('fr-FR')} €
                                        </TableCell>
                                        <TableCell align='center'>
                                            {quotation.status == 'created' &&
                                                <IconButton
                                                    aria-label='delete'
                                                    onClick={() => onDeleteDocument(quotation._id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>}
                                            {(quotation.status == 'created' || quotation.status == 'sent') &&
                                                <IconButton
                                                    aria-label='update'
                                                    onClick={() => navigate("/billing/update/" + quotation._id)}
                                                >
                                                    <EditIcon />
                                                </IconButton>}
                                            <IconButton
                                                aria-label='download'
                                                onClick={() => downloadDocument(quotation)}
                                            >
                                                <DownloadIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ))}
                        </TableBody >
                    </Table >
                </TableContainer >
            </div >

            <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={isDownloading}>
                <CircularProgress color='inherit' />
            </Backdrop>

            {downloadError &&
                <Alert variant="outlined" severity={'error'}>
                    Une erreur est survenue
                </Alert>}
        </>
    );

}))

export default InvoiceTable;