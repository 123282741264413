export interface clients {
	_id?: string;
	firstName: string;
	lastName: string;
	entreprise: string;
	phone: string;
	email: string;
	siret: string;
	address: {
		addressLine: string;
		city: string;
		postalCode: string;
		country: string
	};
	password?: string
	blocked: boolean;
	role: string;
	photo: string;
	lastActivity: Date
}

export class ClientsConstructor implements clients {
	_id: string;
	firstName: string;
	lastName: string;
	entreprise: string;
	phone: string;
	email: string;
	siret: string;
	address: { addressLine: string; city: string; postalCode: string; country: string };
	blocked: boolean;
	role: string;
	photo: string;
	lastActivity: Date;

	constructor() {
		this._id = '';
		this.firstName = '';
		this.lastName = '';
		this.entreprise = '';
		this.phone = '';
		this.email = '';
		this.siret = '';
		this.address = { addressLine: '', city: '', postalCode: '', country: '' };
		this.blocked = false;
		this.role = 'CLIENT';
		this.photo = '';
		this.lastActivity = new Date()
	}
}
