import { inject, observer } from "mobx-react";
import React, { ReactNode, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Divider, Drawer, IconButton, Stack } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DevicesIcon from '@mui/icons-material/Devices';
import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import UserStore from "../Stores/UserStore";
import ApiStore from "../Stores/ApiStore";

interface HeaderMenuProps {
    userStore?: UserStore
    apiStore?: ApiStore,
    headerText: string
    allowBack?: boolean
    children?: ReactNode
}

const HeaderMenu = inject("userStore", "apiStore")(observer(({ userStore, apiStore, headerText, allowBack, children }: HeaderMenuProps) => {

    if (!userStore || !apiStore) {
        return null;
    }

    const [drawerOpen, setdrawerOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const disconnect = async () => {
        try {
            await apiStore.get('auth/logout')
            userStore.disconnect()
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <header>
            <MenuIcon className="hamburger-icon" sx={{ fontSize: 30 }} onClick={() => setdrawerOpen(true)} />
            {
                allowBack &&
                <IconButton onClick={() => navigate(-1)}>
                    <ArrowBackIcon sx={{ fontSize: 35, marginLeft: '10px' }}></ArrowBackIcon>
                </IconButton>
            }
            <h2 style={{ marginLeft: '30px' }}>{headerText}</h2>

            {children}
            <Drawer
                anchor={"left"}
                open={drawerOpen}
                onClose={() => setdrawerOpen(false)}
            >
                <div className="drawer-content">
                    <div>
                        <div className="drawer-menu-container">
                            <Avatar src={userStore.user?.photo}>
                                {userStore.user?.firstName.charAt(0).toUpperCase()}
                                {userStore.user?.lastName.charAt(0).toUpperCase()}
                            </Avatar>
                            <h3 style={{ color: "white" }}>{userStore.fullName}</h3>
                        </div>

                        <Stack
                            divider={<Divider orientation="horizontal" sx={{ backgroundColor: "#4A4A95" }} />}
                        >
                            {userStore.user?.role === 'ADMIN' && 
                            <Link to={"/"} className="navigation-sector-text">
                                <section className="sector-clients">
                                    <HomeIcon />
                                    <h4>Accueil</h4>
                                </section>
                            </Link>}


                            <Link to={"/projects"} className="navigation-sector-text">
                                <section className="sector-clients">
                                    <DevicesIcon />
                                    <h4>Projets</h4>
                                </section>
                            </Link>

                            {
                                userStore.isAdmin &&
                                <Link to={"/clients"} className="navigation-sector-text">
                                    <section className="sector-clients">
                                        <PersonIcon />
                                        <h4>Clients</h4>
                                    </section>
                                </Link>
                            }
                            {
                                userStore.isAdmin &&
                                <Link to={"/billing"} className="navigation-sector-text">
                                    <section className="sector-clients">
                                        <DescriptionIcon />
                                        <h4>Factures et Devis</h4>
                                    </section>
                                </Link>
                            }
                        </Stack>
                    </div>
                    <div>
                        <section className="sector-clients navigation-sector-text drawer-bottom-align">
                            <SettingsIcon />
                            <Link to={"/profile"} style={{ textDecoration: 'none' }}>
                                <h4>Paramètres</h4>
                            </Link>
                        </section>
                        <section className="sector-clients navigation-sector-text">
                            <LogoutIcon />
                            <div onClick={() => disconnect()} >
                                <h4>Déconnexion</h4>
                            </div>
                        </section>
                    </div>
                </div>
            </Drawer>
        </header>
    );
}))

export default HeaderMenu;