
import DescriptionIcon from '@mui/icons-material/Description';
import { folderTree } from '../../Utils/Types/folderTree';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { IconButton, Box, CircularProgress } from "@mui/material";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import DeleteIcon from '@mui/icons-material/Delete';
import { API_URL } from '../../Utils/Variables';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useState } from 'react';
import UserStore from '../../Stores/UserStore';

interface TreeStructureProps {
    userStore?: UserStore,
    documents: folderTree | undefined,
    handleDrop: (event: React.DragEvent<HTMLDivElement>, path: string) => void,
    onDocumentDelete: (tree: folderTree) => void,
    onCreateFolder: (path: string) => void,
    rootName: string,
    loading: boolean
}

const TreeStructure = ({ userStore, documents, handleDrop, onDocumentDelete, onCreateFolder, rootName, loading }: TreeStructureProps) => {
    if (!userStore) {
        return null
    }

    const [pathDraggingOn, setpathDraggingOn] = useState<string>("");

    const NewFolderTree = ({ tree }: { tree: folderTree }) => {
        return (<>
            {tree.children?.map((child: folderTree) => {
                if (child.type === "folder")
                    return <RenderFolder child={child} key={child.name + child.type + child.path} />
                else if (child.type === "file" && child.name !== "")
                    return renderFileName(child, child.name)
            })}
        </>)
    }

    const renderFileName = (tree: folderTree, file: string) => {
        return (
            <div
                key={tree.id}
                className="icon-files-folder"
                onDrop={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleDrop(event, tree.path);
                    setpathDraggingOn("")
                }}
                onDragOver={(event) => {
                    event.preventDefault(); setpathDraggingOn(tree.path)
                }}
                onDragLeave={() => setpathDraggingOn("")}
                onDragExit={() => setpathDraggingOn("")}
            >
                <TreeItem
                    icon={<DescriptionIcon />}
                    nodeId={file}
                    label={file}
                    onClick={() => openFile(tree.name)}
                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
                />
                {userStore.isAdmin &&
                    <div className="file-delete-icon-container">
                        <IconButton onClick={() => onDocumentDelete(tree)}>
                            <DeleteIcon style={{ fill: '#962121' }} />
                        </IconButton>
                    </div>
                }
            </div>
        )
    }

    const RenderFolder = ({ child }: { child: folderTree }) => {
        return (
            <div
                /*draggable        
                onDragStart={(event) => {
                    event.stopPropagation()
                    event.dataTransfer.setData("dirPath", child.name);
                }}  */
                onDrop={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleDrop(event, child.path);
                    setpathDraggingOn("")
                }}
            >
                <TreeItem
                    nodeId={child.path}
                    label={
                        <div
                            className={"folders-containers " + (pathDraggingOn === child.path ? 'folder-drag-over' : '')}
                            style={{ height: '40px' }}
                            onDragOver={(event) => {
                                event.preventDefault(); setpathDraggingOn(child.path)
                            }}
                            onDragLeave={() => setpathDraggingOn("")}
                            onDragExit={() => setpathDraggingOn("")}
                        >
                            <p>{child.name}</p>
                            <IconButton
                                onClick={() => onCreateFolder(child.path)}
                                title="Créer un dossier"
                                sx={{ position: "absolute", right: 40, top: 0, bottom: 0 }}
                            >
                                <CreateNewFolderIcon />
                            </IconButton>
                            {userStore.isAdmin &&
                                <IconButton
                                    onClick={() => onDocumentDelete(child)}
                                    sx={{ position: "absolute", right: 0, top: 0, bottom: 0 }}
                                    title="Supprimer le dossier"
                                >
                                    <DeleteIcon style={{ fill: '#962121' }} />
                                </IconButton>
                            }
                        </div>
                    }
                >
                    <NewFolderTree tree={child} />
                </TreeItem>
            </div>
        )
    }

    const openFile = async (filename: string) => {
        const body = {
            filename: filename
        }

        try {
            const response = await fetch(API_URL + `documents/view`, {
                method: 'POST', // ou 'GET' en fonction de votre backend
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(body)
            });

            if (!response.ok) {
                throw new Error('Erreur lors du téléchargement du fichier');
            }

            const blob = await response.blob();

            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = filename;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
        catch (error) {
            console.error('Erreur lors du téléchargement du fichier', error);
        }
    }

    return (
        <>
            <IconButton
                onClick={() => onCreateFolder(',' + rootName + ',')}
                title="Créer un dossier"
                sx={{ marginLeft: '10px' }}
            >
                <CreateNewFolderIcon />
                <p style={{ fontSize: '18px', marginLeft: '10px', fontWeight: 'bold' }}>Créer un dossier</p>
            </IconButton>

            <Box sx={{ minHeight: 150, flexGrow: 1, maxWidth: 450 }}>
                {loading ?
                    <CircularProgress size={30} />
                    :
                    <>
                        <TreeView
                            aria-label="file system navigator"
                            sx={{ backgroundColor: 'transparent' }}
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                        >
                            {documents?.children && documents.children.length > 0 && documents.children[0].children?.map((child: folderTree) => {
                                // eslint-disable-next-line react/prop-types
                                if (child.type === "file" && child.name !== "") {
                                    // eslint-disable-next-line react/prop-types
                                    return renderFileName(child, child.name)
                                }
                                // eslint-disable-next-line react/prop-types
                                else if (child.type === "folder") {
                                    // eslint-disable-next-line react/prop-types
                                    return <RenderFolder child={child} key={child.id} />
                                }
                            })}
                        </TreeView>

                        <div
                            style={{ minHeight: 600 }}
                            className={pathDraggingOn === (rootName + ',') ? 'folder-drag-over' : ''}
                            onDragOver={(event) => {
                                event.preventDefault(); setpathDraggingOn(rootName + ',')
                            }}
                            onDragLeave={() => setpathDraggingOn("")}
                            onDragExit={() => setpathDraggingOn("")}
                            onDrop={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                handleDrop(event, rootName + ',');
                                setpathDraggingOn("")
                            }}
                        >
                        </div>
                    </>}
            </Box>
        </>)
}


export default TreeStructure