import React, { FormEvent, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Alert, Backdrop, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import UserStore from '../Stores/UserStore';
import ApiStore from '../Stores/ApiStore';
import user from '../Utils/Types/user';
import ClientStore from '../Stores/ClientStore';
import { isAxiosError } from 'axios';

interface LoginProps {
	userStore?: UserStore,
	apiStore?: ApiStore,
	clientStore?: ClientStore
}

const Login = inject(
	'userStore',
	'apiStore',
	'clientStore'
)(
	observer(({ userStore, apiStore, clientStore }: LoginProps) => {

		if (!userStore || !apiStore || !clientStore) {
			return null
		}
		const [email, setemail] = useState<string>();
		const [password, setpassword] = useState<string>();
		const [loading, setloading] = useState<boolean>(false);
		const [badCredentials, setBadCredentials] = useState<boolean>(false);
		const [loginError, setLoginError] = useState<boolean>(false);

		const navigate = useNavigate();

		const attemptLogin = async (event: FormEvent<HTMLInputElement>) => {
			event.preventDefault();

			setloading(true);

			try {
				const payload = {
					email: email,
					password: password,
				};

				const user: user = await apiStore.post('auth/login', payload) as user
				userStore.setUser(user);
				navigate('/');
			}
			catch (error) {
				if (isAxiosError(error) && error.response?.status === 401) {
					console.error('Unauthorized access. Please check your credentials.');
					setBadCredentials(true)
					setTimeout(() => {
						setBadCredentials(false);
					}, 5000);
				} else {
					console.error('An error occurred during login:', error);

					setLoginError(true);
					setTimeout(() => {
						setLoginError(false);
					}, 5000);
				}
			}
			finally {
				setloading(false);
			}
			return false;
		};

		return (
			<>
				<Backdrop sx={{ color: '#fff', zIndex: 1 }} open={loading}>
					<CircularProgress color='inherit' />
				</Backdrop>
				<div className='login-container'>
					<h2 style={{ fontWeight: 'bold' }}>Portail de connexion</h2>
					<form className='login-form'>
						<div>
							<input
								type='email'
								required
								onChange={(e) => setemail(e.target.value)}
								className='login-input'
								placeholder='Email'
							/>
						</div>
						<div>
							<input
								type='password'
								required
								onChange={(e) => setpassword(e.target.value)}
								placeholder='Mot de passe'
								className='login-input'
							/>
						</div>
						<div style={{ margin: '15px' }}>
							<Link to={"/ask-reset-password"}>Mot de passe oublié</Link>
						</div>

						<div style={{ textAlign: 'center' }}>
							<input
								type='submit'
								onClick={(e) => attemptLogin(e)}
								className='login-submit-button'
								value={'Connexion'}
							/>
						</div>
					</form>
				</div>

				{badCredentials &&
					<div className="alert-message">
						<Alert variant="outlined" severity="warning">
							Identifiant ou mot de passe incorrect. Veuillez réessayer.
						</Alert>
					</div>
				}

				{loginError &&
					<div className="alert-message">
						<Alert variant="outlined" severity="error">
							Une erreur est survenue. Veuillez réessayer ultérieurement.
						</Alert>
					</div>
				}
			</>
		);
	}),
);

export default Login;
