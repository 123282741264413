interface Client {
	_id: string;
	label: string;
	firstName: string;
	lastName: string;
}

export interface project {
	_id?: string;
	name: string;
	clients: Client[];
	photo?: string;
	date: { startDate: Date; endDate: Date };
	description: string;
	archived: boolean;
}

export class ProjectConstructor implements project {
	constructor() {
		this._id = '';
		this.name = '';
		this.clients = [];
		this.photo = '';
		this.date = { startDate: new Date(), endDate: new Date() };
		this.description = '';
		this.archived = false;
	}
	_id: string;
	name: string;
	clients: Client[];
	photo?: string | undefined;
	date: { startDate: Date; endDate: Date };
	description: string;
	archived: boolean;
}
