import React from "react";
import HeaderMenu from "../../Components/HeaderMenu";
import CreateInvoiceForm from "../../Components/Invoices/CreateInvoiceForm";
import { useNavigate, useParams } from "react-router-dom";
import { invoice } from "../../Utils/Types/invoice";
import { inject, observer } from "mobx-react";
import ApiStore from "../../Stores/ApiStore";

interface CreateInvoicesProps {
    apiStore?: ApiStore;
}

const CreateInvoices = inject("apiStore")(observer(({ apiStore }: CreateInvoicesProps) => {

    const { type } = useParams()

    if (!type || !apiStore) return null;

    const navigate = useNavigate();

    const createQuotation = async (data: invoice) => {
        try {
            await apiStore.post('billing/create', data);
            navigate('/billing');
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <HeaderMenu headerText={type === 'quotation' ? 'Créer un devis' : 'Créer une facture'} allowBack />

            <CreateInvoiceForm
                docType={type}
                onSubmit={createQuotation}
            />
        </>
    );
}))

export default CreateInvoices;